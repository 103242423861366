import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as TrashIcon } from "../media/icons/trashcan_icon.svg";
import "../css/organizations.css";
import OrganizationLogo from "./OrganizationLogo";
import getUserOrganizations from "../services/organizations/getUserOrganizations";
import deleteUserOrganization from "../services/organizations/deleteUserOrganizations";

export default function Organizations({ authToken }) {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [organizationCodeToDelete, setOrganizationCodeToDelete] = useState(null);

  useEffect(() => {
    async function requestGetOrganizations() {
      let { data, error } = await getUserOrganizations(authToken);
      if (!error) {
        setOrganizations(data);
      } else {
        console.error("Error fetching organizations:", error);
      }
    }

    requestGetOrganizations();
  }, [authToken]);

  const handleOpen = (organizationCode) => {
    setOrganizationCodeToDelete(organizationCode);
    setModalMessage("¿Estás seguro que quieres desvincularte de la organización?");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOrganizationCodeToDelete(null);
  };

  const handleDelete = async () => {
    try {
      let { data } = await deleteUserOrganization(authToken, organizationCodeToDelete);

      if (data.success) {
        setOrganizations(
          organizations.filter((org) => org.code !== organizationCodeToDelete)
        );
      } else {
        console.error("Error al eliminar la organización.");
      }
    } catch (error) {
      console.error("Error deleting organization:", error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <div className="organizations_container">
        <div className="organizations">
          <h1 className="organizations__title">Organizaciones Vinculadas</h1>
          <p className="organizations__text">
            Las vinculaciones a empresas te permiten tener información más
            específica cuando hagas preguntas relacionadas a estas empresas a
            Lisa.
          </p>

          <div className="organizations__list">
            {organizations.map((organization) => (
              <Organization
                key={organization.code}
                organization={organization}
                handleOpen={() => handleOpen(organization.code)}
              />
            ))}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
      >
        <Box className={classes.modalContent}>
          <Typography id="modal-title" variant="h6" className={classes.modalTitle}>
            Desvincular organización
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Box className={classes.modalActions}>
            <Button variant="contained" color="primary" onClick={handleDelete} className={classes.modalButton}>
              Desvincularme
            </Button>
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const Organization = ({ organization, handleOpen }) => {
  const { idType, organizationName, role, id } = organization;
  return (
    <div className="organization">
      <div className="organization__info">
        <div className="organization__icon">
          <OrganizationLogo name={organization.code} />
        </div>
        <div>
          <p className="organization__name">{`${organizationName} - ${role}`}</p>
          <p className="organization__id">
            {idType === "email" ? id : `${idType}: ${id}`}
          </p>
        </div>
      </div>
      <IconButton
        onClick={handleOpen}
        style={{ marginLeft: "auto" }}
        children={<TrashIcon />}
      />
    </div>
  );
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '8px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
    maxWidth: '400px',
    textAlign: 'center',
  },
  modalTitle: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },
  modalButton: {
    backgroundColor: '#8066ec',
    color: '#fff',
    padding: '10px 0',
    width: '100%',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#6851c8',
    },
  },
  cancelButton: {
    marginTop: '16px',
    color: '#6851c8',
    textTransform: 'none',
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});
