import '@fontsource/roboto/500.css'
import '../css/dynamicVideo.css'
import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import VideoPlayer from './videoPlayer'
import OptionsList from './optionsList'
import { nodes } from './nodes'
import Chip from '@mui/material/Chip'
import { SatisfactionSurvey } from './satisfactionSurvey'
import SpeechRecognition from './speechRecognition'
import IconsBar from './IconsBar'
import Cookies from 'universal-cookie'
import { UserLogin } from './UserLogin'
import MicControl from './micControl'
import NavMenu from './navMenu'
import { v4 as uuidv4 } from 'uuid';
import { Faqs } from './faqs'
import { Updates } from './updates'
import { Backdrop, Card, Button } from '@mui/material'
import { shortTerms } from './terms'
import { AccountSettings } from './accountSettings'
import SubscriptionPage from './subscriptions'
import AlertComponent from './AlertComponent'
import {decryptData} from '../hooks/useDataDecrypt'
import Organizations from  "./Organizations"
import { setSessionValue, getSessionValue, deleteSessionValue } from "../utils/SessionManager"

const cookies = new Cookies()
const DATE = new Date()
const COOKIES_EXPIRE = new Date('12-01-' + (DATE.getFullYear() + 1))
const DOMAIN = process.env.REACT_APP_DOMAIN

class DynamicVideo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            video: nodes[0].video,
            ended: false,
            finish: false,
            pause: false,
            listening: false,
            update: false,
            node: 0,
            updateNode: {},
            welcomeVideo: '',
            conversational: false,
            hasDisplayTrialEnd: false,
            displayTrialMessage: false,
            token: null,
            limited: false,
            hasLogin: false,
            trial: 0,
            sessionId: null,
            goLogin: true
        }
        this.updateState = this.updateState.bind(this)
        this.displayOptions = this.displayOptions.bind(this)
        this.close = this.close.bind(this)
        this.finish = this.finish.bind(this)
        this.hide = this.hide.bind(this)
        this.back = this.back.bind(this)
        this.pauseVideo = this.pauseVideo.bind(this)
        this.playVideo = this.playVideo.bind(this)
        this.actualNode = this.actualNode.bind(this)
        this.startSpeechListening = this.startSpeechListening.bind(this)
        this.stopSpeechListening = this.stopSpeechListening.bind(this)
        this.loadNode = this.loadNode.bind(this)
        this.goHome = this.goHome.bind(this)
        this.switchMode = this.switchMode.bind(this)
        this.setToken = this.setToken.bind(this)
        this.setId = this.setId.bind(this)
        this.logout = this.logout.bind(this)
        this.share = this.share.bind(this)
        this.displayPage = this.displayPage.bind(this)
        this.setSound = this.setSound.bind(this)
        this.trialCount = this.trialCount.bind(this)
        this.login = this.login.bind(this)
    }



    componentDidMount() {

        if (DOMAIN === 'lisa.do'){
            
            navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {
                window.localStream = stream;
            })
            .catch((err) => {
                
            });

                
            var token = getSessionValue('token')|| this.props.url_token
            
            if (token !== '' && token !== undefined && token !== null) {
                this.setToken(token)
                this.setState({ conversational: true })
                this.setState({ hasLogin: true })
                this.setState({ goLogin: false })
            }
            else{
                this.setState({ hasLogin: false })
            }

            if (this.props.url_token){
                decryptData(this.props.url_token)
                .then(({data, loading, error}) => {
                    let organization = data?.organization
                    let linked = data?.linked
                    let logged_in = data?.logged_in

                    if (logged_in){
                        this.setToken(this.props.url_token)
                        this.setState({ conversational: true })
                        this.setState({ hasLogin: true })
                    }
                    if (data && linked==='true'){
                        this.setState({alertType:'success'})
                        this.setState({alertMessage:`Tu cuenta se ha vinculado con éxito a ${organization}`})
                    }else if(linked==='false'){
                        this.setState({alertType:'error'})
                        this.setState({alertMessage:`No se pudo vincular a la organización `}) 
                    }
                })
                .catch(error => {
                
                });
            }
            
        }

        if (!this.state.sessionId) {
            let sessionId = uuidv4()
            this.setState({ sessionId: sessionId })
        }

        var date = new Date().getHours()
        const element = ReactDOM.findDOMNode(this)
        this.setState({ element: element })
            

        // good morning video
        if (date >= 5) {
            if (DOMAIN !== 'lisa.do'){
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/17_05_2022%20Intro%20Afiliados%20-%20Buenos%20das_x264.mp4' })
            }
            else{
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/SALUDOS%20MAANA.mp4' })
            }
            
        }
        // good afternoon video
        if (date >= 12) { 
            if (DOMAIN !== 'lisa.do'){
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/17_05_2022%20Intro%20Afiliados%20-%20Buenas%20tardes_x264.mp4' }) 
            }
            else{
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/SALUDOS%20TARDE.mp4' })
            }
         }
        // good night video
        if (date > 18) { 
            if (DOMAIN !== 'lisa.do'){
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/17_05_2022%20Intro%20Afiliados%20-%20Buenas%20noches_x264.mp4' })
            }
            else{
                this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/SALUDOS%20NOCHE.mp4' }) 
            }
            

        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get('content') !== null) {
            this.loadNode(nodes[24])
            this.setState({ video: nodes[24].video })
            this.setState({ limitedLink: true })
        }

        if (DOMAIN === 'lisa.do') {
            this.setState({ conversational: true })
        }

    }

    /// function to handle right click to desible options
    handleContextMenu = (e) => {
        e.preventDefault()
    }


    componentDidUpdate() {
        if (DOMAIN === 'lisa.do'){
            var token = getSessionValue('token') || this.props.url_token
            if (token && this.state.goLogin === true){
                this.setState( {hasLogin : true})
                this.setState({ goLogin: false })
            }
            var trialQ = 6 //cookies.get('Tq5') ? cookies.get('Tq5') : this.state.trial


            if (trialQ < 6 && this.state.token === null && !token  && token !== null && !this.state.hasLogin && !this.state.hasAcceptedTerms && !this.state.displayTerms) {
                this.setState({ trial: trialQ })
                if (!this.state.hasAcceptedTerms && !this.state.displayTerms) {
                    this.setState({ displayTerms: true })
                } 
            
            if (trialQ < 6 && this.state.token === null && !token  && !this.state.hasLogin && this.state.hasAcceptedTerms) {
                this.setState({ token: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwdWJsaWNfaWQiOiI4MDllZDcyYi0zMzQ4LTQxNDUtYWM1OS1mOTk1YTQ0ODYxYjAiLCJleHAiOjE3MjIwMTc5NjZ9.GEr--4cMXSC9VJbyFdy9GvvxiY7bqq2YKhPLnw9PKYs` })
            }


            } else if (!token && trialQ === 6 && !this.state.hasDisplayTrialEnd  && !this.state.hasLogin && token !== null) {
                this.setState({ trial: trialQ })
                this.setState({ token: null })

                if (!this.state.hasDisplayTrialEnd) {
                    this.setState({ displayTrialMessage: true })
                    this.setState({ hasDisplayTrialEnd: true })
                }
            }

            
        }


        if (this.state.conversational || !this.state.finish) {
            const element = ReactDOM.findDOMNode(this)
            if (element) {
                var optionList = element.querySelector('.list')
                if (optionList) {
                    optionList.setAttribute("hidden", true)
                }
            }

        }

        // if (this.state.conversational && trialQ >= 6 && !this.state.hasLogin && !this.state.goLogin) {
        //     this.setState({ goLogin: true })
        // }

    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick)
        document.removeEventListener("contextmenu", this.handleContextMenu)
    }



    switchMode(value = false) {
        if (!this.state.conversational) {
            this.setState({ conversational: true })
            if (value) {
                this.setState({ limited: true })
                this.startSpeechListening()
            }
        }
        if (this.state.conversational) {
            this.setState({ conversational: false })
            this.setState({ limited: false })
            this.updateState({ video: '' })
            this.updateState({ video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/PENSANDO.mp4' })
            this.displayOptions()
            // this.updateState({ video: nodes[0].video })
        }
        this.setState({ selectedPage: null })
    }

    setToken(token) {
        this.setState({ token: token })
    }

    setId(id) {
        this.setState({ id: id })
    }

    handleAccepTerms() {
        this.setState({ displayTerms: false })
        this.setState({ hasAcceptedTerms: true })
        this.setState({ conversational: true })
        this.playVideo()
    }

    render() {
        let hasLogin = this.state.hasLogin
        const pages = {
            'faqs': <Faqs />,
            'updates': <Updates />,
            'account': <AccountSettings auth_token={this.state.token} />,
            // 'subscriptions': <SubscriptionPage authToken={this.state.token} />,
            'organizations': <Organizations authToken={this.state.token}/>
            
        }

        const steps = [
            {
                disableBeacon: false,
                target: '.home',
                content: 'Tienes preguntas sobre la adquisicion de acciones publicas en Cesar Iglesias',
            }
        ]

        const card = <div style={{ padding: '6%' }}>
            <h3>Regístrate o Inicia Sesión</h3>
            <div style={{ marginTop: '20px', maxHeight: '38vh' }}>
                <p>{'Has alcanzado el límite de preguntas. Regístrate para seguir utilizando a LISA.'}</p>
            </div>
            <Button style={{ marginTop: '20px' }} onClick={() => this.setState({ displayTrialMessage: false })}>Cerrar</Button>
        </div>

        const cardTerms = <div style={{ padding: '6%', zIndex: '10000' }}>
            <h3>Términos y Condiciones</h3>
            <div style={{ marginTop: '20px', height: '38vh', overflow: 'scroll' }}>
                {shortTerms}
            </div>
            <Button style={{ marginTop: '20px' }} onClick={() => this.handleAccepTerms()}>Aceptar</Button>
        </div>

        const video = this.state.video
        const element = this.state.element
        const closeLabel = <span>Finalizar <span className='icon'>X</span ></span>
        let micControl = ''
        let page = ''
        if (this.state.conversational) {
            micControl = <MicControl startSpeechListening={this.startSpeechListening} stopSpeechListening={this.stopSpeechListening} listeningOn={this.state.listening} />
        }

        if (this.state.selectedPage) {
            page = <div className='current-page-container '>
                <Chip className="close-audio" label={<span style={{ color: 'white' }}>X</span >} variant="outlined" onClick={() => this.setState({ selectedPage: false })} style={{ zIndex: 10000, position: 'absolute' }} />
                {this.state.conversational &&
                    <NavMenu
                        goHome={this.goHome}
                        hasLogin={hasLogin}
                        trial={this.state.trial > 5 ? false : true}
                        token={this.state.token}
                        login={this.login}
                        isConversational={this.state.conversational}
                        logout={this.logout}
                        share={this.share}
                        selectPage={this.displayPage} />}
                {pages[this.state.selectedPage]}
            </div>
        }

        if ((this.state.conversational && !this.state.limited && !this.state.hasLogin && this.token === null) || (DOMAIN ==='lisa.do' && this.state.goLogin)) {
            return (
                <React.Fragment>
                    {/* <Backdrop
                        style={{ zIndex: '100000' }}
                        open={this.state.displayTrialMessage}>
                        <Card className='terms' style={{ marginTop: '30%', maxHeight: '65%', zIndex: '100000', width: '90%', maxWidth: '450px' }}
                            variant="outlined">
                            {card}
                        </Card>
                    </Backdrop> */}
                    <UserLogin
                        setToken={this.setToken}
                        setId={this.setId}
                        backButton={() => this.setState({ conversational: false }) && this.setState({ goLogin: false })}
                        skipPreScreening={this.state.goLogin}
                        setState={this.updateState} />
                </React.Fragment>
            )
        }

        return (
            <div>
                {page}
                <Backdrop
                    style={{ zIndex: 100000 }}
                    open={this.state.displayTerms}>
                    <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => this.setState({ displayTerms: false })} style={{ zIndex: 10000 }}> </Chip>
                    <Card className='terms' style={{ marginTop: '30%', height: '65%', width: '90%' }}
                        variant="outlined">
                        {cardTerms}
                    </Card>
                </Backdrop>
                {this.state.conversational &&
                    <NavMenu
                        hasLogin={hasLogin}
                        goHome={this.goHome}
                        trial={this.state.trial >= 6 ? false : true}
                        token={this.state.token}
                        login={this.login}
                        isConversational={this.state.conversational}
                        logout={this.logout}
                        share={this.share}
                        selectPage={this.displayPage} />}
                <IconsBar shareElement={this.state.share} setShare={this.share} switchMode={this.switchMode} limitedLink={this.state.limitedLink} limited={this.state.limited} mode={this.state.conversational} />

                <SatisfactionSurvey ended={this.state.ended} back={this.back} hide={this.hide} />
                {/* <Chip className="close" label={closeLabel} variant="outlined" onClick={() => this.close()} > </Chip> */}
                {DOMAIN === 'lisa.do' && <SpeechRecognition
                    actualNode={this.state.node}
                    updateState={this.updateState}
                    loadNode={this.loadNode}
                    listeningOn={this.state.listening}
                    startSpeechListening={this.startSpeechListening}
                    stopSpeechListening={this.stopSpeechListening}
                    id={this.state.id}
                    auth_token={this.state.token}
                    sessionId={this.state.sessionId}
                    close={this.close}
                    playVideo={this.pla}
                    sound={this.state.sound}
                    setSound={this.setSound}
                    trialCount={this.trialCount}
                    conversational={this.state.conversational}
                    limited={this.state.limited}
                    switchMode={this.switchMode}/>}

                <OptionsList
                    className="options-list" close={this.close}
                    updateState={this.updateState}
                    domelement={element}
                    update={this.state.update}
                    actualNode={this.actualNode}
                    pause={this.pauseVideo}
                    updateNode={this.state.updateNode}
                    switchMode={this.switchMode}
                />
                {process.env.REACT_APP_DOMAIN !== 'lisa.do' && <VideoPlayer showIcon={this.state.conversational}
                    className="videoPlayer1"
                    url={video}
                    ended={this.finish}
                    displayOptions={this.displayOptions}
                    updateState={this.updateState}
                    pause={this.state.pause}
                    listeningOn={this.state.listening}
                    limited={this.state.limited}
                />}
                {micControl}
                {this.state.alertMessage && <AlertComponent message={this.state.alertMessage} type={this.state.alertType} />}
            </div>
        )


    }

    updateState(values) {
        this.setState({ listening: false })
        this.setState(values)
    }

    loadNode(values) {
        this.setState({ update: true })
        this.setState({ updateNode: values })
    }

    finish(value) {

        if (value === true && this.state.finish === true) {
            this.setState({ ended: true })
        }
    }

    close() {
        if (!this.state.conversational) {
            var optionList = this.state.element.querySelector('.list')
            if (optionList) {
                optionList.setAttribute("hidden", true)
            }

        }
        var closeButton = this.state.element.querySelector('.close')
        // closeButton.setAttribute("hidden", true)
        // this.setState({ finish: true })
        // this.setState({ ended: false })
        // this.setState({ video: Finalizar })
    }

    back() {
        this.hide()
        window.location.reload()
    }

    hide() {
        this.setState({ ended: false })
        this.setState({ pause: false })
        this.setState({ finish: false })
    }

    displayOptions() {
        let element = ReactDOM.findDOMNode(this)
        if (!this.state.finish && !this.state.conversational) {
            var optionList = element.querySelector('.list')
            if (optionList) {
                optionList.removeAttribute("hidden")
            }

        }
    }
    actualNode(values) {
        this.setState({ node: values })
        if (values === 4) {
            setSessionValue('afiliado', true)

        }
        if (values === 2) {
            setSessionValue('visitante', true)
        }
    }

    pauseVideo() {
        this.setState({ pause: true })
    }

    playVideo() {
        this.setState({ pause: false })
    }

    startSpeechListening() {
        if (!this.state.listening) {
            this.setState({ listening: true })
        }
    }

    stopSpeechListening() {
        if (this.state.listening) {
            this.setState({ listening: false })
        }
    }

    goHome() {
        
        setSessionValue('afiliado',false)
        setSessionValue('visitante',false)

        this.updateState({ video: nodes[0].video })
        this.loadNode(nodes[0])
        this.actualNode(0)
        this.setState({ conversational: false })
        this.setState({ selectedPage: null })
        this.switchMode(false)
    }

    logout() {
        setSessionValue('token','')
        this.setState({ selectedPage: null })
        this.setState({ hasLogin: false })
        this.setState({ token: null })
        this.setState({goLogin: true})
        window.location.replace('/')
    }

    login() {
        this.setState({ goLogin: true })
    }


    trialCount(add = true) {
        var count = getSessionValue('Tq5') ? getSessionValue('Tq5') : this.state.trial
        if (count >= 6 && !this.state.token) {
            return false
        }
        if (add) {
            count = parseInt(count) + 1
            this.setState({ trial: count })
            setSessionValue('Tq5')
        }
        return true
    }

    share(element) {
        this.setState({ share: element })
    }

    displayPage(page) {
        this.setState({ selectedPage: page })
    }

    setSound(link) {
        this.state.sound.play()
    }

}

export default DynamicVideo