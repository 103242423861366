import React from "react";
import { makeStyles } from '@mui/styles';
import AFPCrecerLogo from "../media/organizations/afp_logo.png";
import SegurosCrecerLogo from "../media/organizations/seguros_logo.png";
import MissingIcon from "../media/icons/missing.png"
import CrecerLabLogo from "../media/organizations/crecer_lab.png"

const useStyles = makeStyles({
  imageContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const OrganizationLogo = ({ name }) => {
  const classes = useStyles();

  const organizationLogos = {
    afp_crecer: AFPCrecerLogo,
    seguros_crecer: SegurosCrecerLogo,
    crecer_lab: CrecerLabLogo
  };

  let url = organizationLogos[name] || MissingIcon;
  let alt_text = organizationLogos[name] ? `${name}_logo` : "missing_logo";

  return (
    <div className={classes.imageContainer}>
      <img src={url} alt={alt_text} width={32.1} height={32} />
    </div>
  );
};

export default OrganizationLogo;
