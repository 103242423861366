class SessionStorage {
    constructor(storageKey = 'sessionvalue') {
        const environment = process.env.REACT_APP_ENV || 'development'; // 'development' is the fallback
        const key = `${storageKey}_${environment}`;
        this.storageKey = key;
    }

    setValue(value) {
        localStorage.setItem(this.storageKey, value);
    }

    getValue() {
        return localStorage.getItem(this.storageKey);
    }

    clearValue() {
        localStorage.removeItem(this.storageKey);
    }

    hasvalue() {
        return this.getValue() !== null;
    }
}


export function setSessionValue(storageKey, value) {
    const sessionStorage = new SessionStorage(storageKey)
    sessionStorage.setValue(value)
}

export function getSessionValue(storageKey) {
    const sessionStorage = new SessionStorage(storageKey)
    return sessionStorage.getValue()
}

export function deleteSessionValue(storageKey) {
    const sessionStorage = new SessionStorage(storageKey)
    sessionStorage.clearValue()
}