import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Logo from "../media/Morado_Lisa_Logo.png";
import "../css/faqs.css";

const data = [
  {
    title: "¿De qué se trata Lisa?",
    content: `La asistente virtual, Lisa, es una creación de la empresa CrecerLab en República Dominicana. Diseñada con tecnología de inteligencia artificial generativa, Lisa es amigable, servicial y está disponible las 24 horas del día, los 7 días de la semana. Con una capacidad veloz y eficiente, puede comprender y procesar rápidamente información, proporcionando respuestas precisas y útiles en tiempo real. A través de sus distintas funciones, Lisa actúa como asistente, experta, representante y consejera, ofreciendo una amplia gama de servicios para facilitar diversas tareas y decisiones.`,
  },
  {
    title: "¿Lisa da respuestas precisas y seguras?",
    content: `Lisa es un proyecto experimental en constante mejora. Aunque se esfuerza por brindar respuestas precisas, siempre verifique la información proporcionada. Cientos de evaluadores han contribuido a su desarrollo antes de su lanzamiento público, garantizando calidad y seguridad. Si bien Lisa tiene controles de seguridad, tenga en cuenta que ocasionalmente puede mostrar información inexacta. `,
  },
  {
    title: "¿Puede Lisa explicar cómo funciona?",
    content: `Sí, Lisa puede explicar por sí misma sus principales funciones.`,
  },
  {
    title: "¿Lisa recuerda lo que dije antes en una conversación?",
    content: `Si, Lisa tiene la capacidad de recordar tus últimas interacciones en la conversión.`,
  },
  {
    title: "¿Cómo puedo ayudar a que Lisa sea mejor?",
    content: `Puedes ayudar a que Lisa sea mejor enviando un correo a Info@crecerlab.com con observaciones de sus respuestas.`,
  },
  {
    title: "¿Quién puede usar Lisa?",
    content: `Para utilizar Lisa, debes tener 18 años de edad o más.`,
  },
  {
    title: "¿Cuántos idiomas habla Lisa?",
    content: `De momento Lisa está diseñada para hablar en español.`,
  },
  {
    title: "¿Qué datos se recopilan? ¿Cómo se usa?",
    content: `Lisa recopila una variedad de datos. Estos incluyen tus conversaciones, información relacionada con el uso del producto, la zona general en la que te encuentras, y tus comentarios sobre el servicio. Para más información accede a <a style="color: blue;" href="https://www.soylisa.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">términos y condiciones</a>.`,
  },
];

export function Faqs() {
  return (
    <React.Fragment>
      <div className="faqs-container">
        <img
          src={Logo}
          className="form-logo"
          alt="AFP crecer"
          style={{
            position: "relative",
            maxWidth: "100px",
            zIndex: 11,
            margin: "0 auto",
          }}
        ></img>
        <Typography
          sx={{
            margin: "60px 0px 0px 0px",
            fontFamily: "Figtree-Regular",
            color: "#fff",
          }}
        >
          <h1>{"Preguntas Frecuentes"}</h1>
        </Typography>
        <Typography
          sx={{
            margin: "0px 0px 60px 0px",
            fontFamily: "Figtree-Regular",
            color: "#fff",
          }}
        >
          Todo lo que necesitas saber sobre Lisa.
        </Typography>
        {data.map((item) => {
          return (
            <Accordion
              sx={{
                maxWidth: "800px",
                width: "100%",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Figtree-Regular",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ color: "#fff", fontFamily: "Figtree-Regular" }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </React.Fragment>
  );
}
