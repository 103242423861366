const addCustomerToOrganization = async (token, organization) => {
    let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/add_customer_of/`
    const requestInfo = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify({
            "organization": organization
        })
    };
    const request = await fetch(url, requestInfo)
    let response = await request.json()
    return response
}

export default addCustomerToOrganization;