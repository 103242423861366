import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {
  Backdrop,
  Grid,
  Typography,
  IconButton,
} from "@mui/material/";
import { useReactPWAInstall } from "react-pwa-install";
import LinkUserWithOrg from "./LinkUserWithOrg";
import UseGetUserInfo from "../hooks/useGetUserInfo";
import { ReactComponent as MyMenuIcon } from "../media/icons/menu.svg";
import LogoCrecerLab from "../media/icons/crecerlab_logo.png";
import {
  Avatar,
  Install,
  LinkWithOrg,
  Logout,
  Questions,
  Share,
  Subscriptions,
  Updates,
  ChevronLeft,
} from "./NavBarIcons";

export default function NavMenu({
  goHome,
  login,
  logout,
  isConversational,
  share,
  selectPage,
  token,
  trial,
  hasLogin,
}) {
  const [displayLink, setDisplayLink] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { getUserInfo, user_info, error, success, isLoading } =
    UseGetUserInfo();

  useEffect(() => {
    if (!success && !isLoading && hasLogin) {
      getUserInfo(token);
    }
  }, [success, isLoading, hasLogin, getUserInfo, token]);

  const { pwaInstall } = useReactPWAInstall();

  const featureProps = {
    variant: "contained",
    children: "Características clave",
  };

  const handleInstallClick = () => {
    pwaInstall({
      title: "Instalar Lisa",
      features: (
        <Typography variant={featureProps.variant}>
          <h5>{featureProps.children}</h5>
          <ul>
            <li>Accede de forma rapida</li>
            <li>Recibe notificaciones de tus procesos en curso</li>
            <li>Potencia tu rendimiento con informacion verificada</li>
          </ul>
        </Typography>
      ),
      description: (
        <Typography variant={featureProps.variant}>
          <h5>Descripción</h5>
          <p>
            Asistente virtual que te ayuda a potenciar tus capacidades, para
            cumplir tus objetivos.
          </p>
        </Typography>
      ),
    })
      .then(() => alert("Se ha instalado la app"))
      .catch(() => alert("Puedes instalar la app en otro momento"));
  };

  let navList = [
    {
      name: "Vincularme a una organización",
      action: (e) => setDisplayLink(true),
      icon: <LinkWithOrg />,
      order: 0,
    },
    {
      name: "Organizaciones vinculadas",
      action: (e) => selectPage("organizations"),
      icon: <CorporateFareIcon />,
      order: 2,
    },
    // {
    //   name: "Suscripciones",
    //   action: (e) => selectPage("subscriptions"),
    //   icon: <Subscriptions />,
    //   order: 1,
    // },
    {
      name: "Preguntas frecuentes",
      action: () => selectPage("faqs"),
      icon: <Questions />,
      order: 2,
    },
    {
      name: "Actualizaciones",
      action: () => selectPage("updates"),
      icon: <Updates />,
      order: 3,
    },
    {
      name: "Compartir",
      action: (e) => share(e.target),
      icon: <Share />,
      order: 3,
    },
    {
      name: "Instalar en el escritorio",
      action: () => handleInstallClick(),
      icon: <Install />,
      order: 0,
    },
    {
      name: "Cerrar sesión",
      action: () => logout(),
      icon: <Logout />,
      order: 99,
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => {
    const menuItems = [
      ...navList.map((item) => ({
        name: item.name,
        action: item.action,
        icon: item.icon,
        order: item.order,
      })),
    ];

    return (
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 349,
          paddingLeft: "37px",
          paddingTop: "61px",
          paddingRight: "38px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto", // Enable vertical scrolling
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: "57px" }}>
            <Grid container alignItems="center" className="nav-header">
              <Grid item sx={{ marginRight: "16px" }}>
                <Avatar />
              </Grid>
              <Grid item>
                <h4 className="account-title">{user_info && user_info.name}</h4>
                <p
                  className="account-link"
                  onClick={() => selectPage("account")}
                >
                  Ver detalles de la cuenta
                </p>
              </Grid>
            </Grid>
          </div>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                key={item.name}
                disablePadding
                sx={{ mb: index !== menuItems.length - 1 ? "32px" : "0" }}
              >
                <div
                  onClick={item.action}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontFamily: "Figtree-Regular",
                      color: "#ff",
                    }}
                    primary={item.name}
                  />
                </div>
              </ListItem>
            ))}
          </List>
        </div>

        <div style={{ paddingBottom: 52 }}>
          <img
            src={LogoCrecerLab}
            alt="Logo CrecerLab"
            style={{ marginLeft: -7 }}
          />
          <Typography
            sx={{ fontFamily: "Figtree-Regular", color: "#fff", mt: 1 }}
          >
            Versión: 2.1.
          </Typography>
        </div>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          style={{
            position: "absolute",
            right: -25,
            bottom: 30,
            backgroundImage:
              "linear-gradient(142deg, #8066ec -44%, #d5a9ff 40%, #59d1ff 125%)",
            padding: 12,
            border: "solid 2px #8066ec",
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Box>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1001,
      }}
    >
      <React.Fragment key={"nav"}>
        <MyMenuIcon
          onClick={toggleDrawer("left", true)}
          className="nav-menu"
          style={{
            width: "50px",
            height: "50px",
            background: "rgba(0, 0, 0, 0.28)",
            color: "white",
            borderRadius: "50%",
            margin: "10px 10px",
            padding: "5px",
          }}
        />

        <SwipeableDrawer
          className="main-navigation-container"
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
          style={{
            zIndex: 30000,
          }}
          sx={{
            "& .MuiPaper-root": {
              overflow: "visible",
            },
          }}
        >
          {list("left")}
        </SwipeableDrawer>
        {displayLink && (
          <>
            <Backdrop open={displayLink} className="link-org">
              <LinkUserWithOrg setDisplay={setDisplayLink} token={token} />
            </Backdrop>
          </>
        )}
      </React.Fragment>
    </div>
  );
}
