import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/require-password-reset/'
const PR_INFO = {
  success: false,
  code_requested: false,
  pin_confirmed: false,
}

/**
 * Require Reset Password
 */
export default function RequirePasswordReset() {
  const [prInfo, setPrInfo] = useState(PR_INFO)

  const requirePasswordReset = async (phone, code) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone: phone, code: code })
    };
    setPrInfo({ ...PR_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setPrInfo({
            success: true,
            code_requested: true,
            pin_confirmed: result.confirmed_pin
          })
        } else {
          setPrInfo({
            code_requested: code ? true : false,
          })
        }
      })
      .catch(error => {

        setPrInfo(c => ({ ...c, error }))
      })
  }
  return { ...prInfo, requirePasswordReset }
}
