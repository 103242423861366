/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react'
import '../css/speechRecognition.css'
import { Backdrop } from '@mui/material'
import Room from './Room'
import Stack from '@mui/material/Stack'
import Lottie from 'react-lottie'
import '../css/voiceInterface.css'
import Logo from '../media/Morado_Lisa_Logo.png'
import useWebsocket from '../hooks/useWebsocket'
import { v4 as uuidv4 } from 'uuid';
import audioAnimationData from '../media/icons/talking_animation.json'
import loadingAnimation from '../media/icons/loading_animation.json'
import loadingStream from '../media/icons/loading_stream.json'
import AnswerFeedBack from './AnswerFeedback'
import AnimatedLoadingStream from "../components/AnimatedLoadingStream"
// const accountStateAudio = ''
const sdk = require("microsoft-cognitiveservices-speech-sdk")
const speechConfig = sdk.SpeechConfig.fromSubscription("e467e2dc3c9b4ec2887248735495d109", "eastus")
speechConfig.speechRecognitionLanguage = "es-MX"
let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput()
let audioChunks = []
let audioBuffers = []; // Queue of audio buffers
let context = new window.AudioContext();    // Audio context
let source = context.createBufferSource(); // Current audio source
let oneTime = true
let stopedAudio = false
let analyser

function Dictaphone({ updateState, listeningOn, stopSpeechListening, auth_token, sessionId,
    limited, conversational, trialCount }) {
    const [joined, setJoined] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [conversation, setConversation] = useState([])
    const [loading, setLoading] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [recognizing, setRecognizing] = useState(false)
    const [lastChunk, setLastChunk] = useState()
    const [roomToken, setRoomToken] = useState()
    const [roomName, setRoomName] = useState()
    const [streamLoadingStatus, setstreamLoadingStatus] = useState(null)



    const {
        message,
        audio,
        action,
        handleSubmit,
        connect,
        disconnect,
        cancelTasks,
        isConnected
    } = useWebsocket()


    const recognizer = useMemo(() => new sdk.SpeechRecognizer(speechConfig, audioConfig), []);


    recognizer.recognized = (s, e) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            stopAudio()
            setRecognizing(false)
            const existsInConversation = conversation.some(item => item.role === 'user' && item.content === e.result.text);
            if (!existsInConversation) {
                setConversation([...conversation, { role: 'user', content: e.result.text, id: uuidv4() }])
                handleSubmit(e.result.text, auth_token, sessionId)
                setLoading(true)
            }
        }
    }

    recognizer.canceled = (s, e) => {
        stopSpeechListening()
    }



    // Create a function to play the next buffer
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function playNextBuffer() {
        try {
            setLoading(false)
            setPlaying(true)
            if (audioBuffers.length === 0 || stopedAudio) {
                oneTime = true
                setPlaying(false)
                return;
            }

            // analyser = context.createAnalyser()
            // Create a new source node

            source = context.createBufferSource();
            source.buffer = audioBuffers.shift(); // Remove the first buffer from the queue

            // Connect to the final output node (the speakers)
            // source.connect(analyser);
            source.connect(context.destination);

            // Play immediately
            source.start(0);

            // When the buffer finishes playing, play the next one
            source.onended = playNextBuffer

        }
        catch (e) {
            // console.log(e)
        }
    }

    useEffect(() => {
        if (!initialized && auth_token) {
            disconnect()
            connect()
            handleSubmit('hola', auth_token, sessionId)
            setInitialized(true)
        }

        if (analyser) {
            let data = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(data);
        }


        if (listeningOn) {
            // checkPermissions()
            if (!recognizing) {
                setRecognizing(true)
                recognizer.startContinuousRecognitionAsync()
            }

        } else {
            setRecognizing(false)
            recognizer.stopContinuousRecognitionAsync()
        }
        if (!recognizing) {
            recognizer.stopContinuousRecognitionAsync()
        }


    }, [listeningOn, stopSpeechListening, recognizer,
        setInitialized, setConversation, playing, stopAudio.apply, isConnected])

    useEffect(() => {
        if (audio.chunk && audio.chunk !== 'ended') {
            // Convert base64 string to ArrayBuffer
            let binaryString = window.atob(audio.chunk);
            let len = binaryString.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            audioChunks.push(bytes.buffer);
        }
        else
            if (audio.chunk && audio.chunk === 'ended' && audioChunks.length) {
                try {
                    let totalLength = audioChunks.reduce((total, arr) => total + arr.byteLength, 0);
                    let result = new Uint8Array(totalLength);
                    let offset = 0;
                    for (let arr of audioChunks) {
                        result.set(new Uint8Array(arr), offset);
                        offset += arr.byteLength;
                    }

                    // Decode the audio data
                    context.decodeAudioData(result.buffer, function (buffer) {
                        // Add the buffer to the queue
                        audioBuffers.push(buffer);
                        if (oneTime) {
                            playNextBuffer()
                            oneTime = false
                        }
                    });
                } catch (e) {
                    // console.log(e)
                }

                audioChunks = []

            }
    }, [audio])

    useEffect(() => {
        if (action && action.name === 'video_call') {
            recognizer.stopContinuousRecognitionAsync()
            setLoading(false)
            setRoomToken(action.token)
            setRoomName(action.room)
            setJoined(true)
        }
    }, [action])

    useEffect(() => {
        if (!audio && streamLoadingStatus == null) {
            setstreamLoadingStatus("loading")
        } else if (streamLoadingStatus == "loading") {
            setstreamLoadingStatus("loaded")
        }
    }, [audio])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: audioAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading'
        }
    }

    const stopedOptions = {
        loop: false,
        autoplay: false,
        animationData: audioAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading',
            play: false
        }
    }

    const loadingAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading',
            play: false
        }
    }

    const loadingStreamOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingStream,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading',
            play: false
        }
    }


    if ((!joined && conversational)) {
        return (
            <React.Fragment>
                <Backdrop
                    sx={{ color: 'black', zIndex: 1000, padding: '20px', alignItems: 'flex-start' }}
                    open={true}
                    className="main-container">

                    <img src={Logo} alt='AFP crecer' style={{ position: 'absolute', maxWidth: '100px', zIndex: 11 }}></img>



                    <Stack spacing={0} className='msg-container' justifyContent="center"
                        alignItems="center" flex={''} height={'100%'}>

                        {streamLoadingStatus === "loading" && (
                            <AnimatedLoadingStream option={loadingStreamOptions} label={"Lisa está conectando..."}/>

                        )}

                        {streamLoadingStatus !== "loading" && playing && (
                            <Lottie
                                options={defaultOptions}
                                height={400}
                                width={400}
                                isClickToPauseDisabled={true}
                            />
                        )}

                        {streamLoadingStatus !== "loading" && !playing && !loading && (
                            <Lottie
                                options={stopedOptions}
                                height={400}
                                width={400}
                                isClickToPauseDisabled={true}
                                isStopped={true}
                            />
                        )}

                        {streamLoadingStatus !== "loading" && loading && (
                            <Lottie
                                options={loadingAnimationOptions}
                                height={400}
                                width={400}
                                isClickToPauseDisabled={true}
                            />
                        )}

                        {!loading && <AnswerFeedBack token={auth_token} />}
                    </Stack >

                    <Backdrop
                        sx={{ color: 'White', backgroundColor: 'transparent', zIndex: 1500, padding: '20px' }}
                        open={listeningOn}>
                    </Backdrop>
                </Backdrop >

            </React.Fragment >
        )
    } else if (joined) {
        return (
            <div className="player-container" style={{ 'zIndex': 10000 }}>
                <Room
                    token={roomToken}
                    roomName={roomName}
                    onDisconnected={() => disconnected()}
                    errorHandle={() => errorHandle()}
                />
            </div >
        )
    }



    if (!joined) {
        return (
            <Backdrop
                sx={{ color: 'White', backgroundColor: 'transparent', zIndex: 1500, padding: '20px', backdropFilter: 'blur(1px)' }}
                open={listeningOn}>
            </Backdrop>
        )
    }




    function disconnected() {
        setJoined(false)
    }

    function errorHandle() {
        setJoined(false)
    }


    function stopAudio() {
        setPlaying(false)
        cancelTasks()
        audioBuffers = []
        context = new window.AudioContext()
        try {
            source.stop()
        }
        catch (e) {
            //   console.log(e)
        }
    }



}
export default Dictaphone