import '../css/App.css';
import '../css/accountState.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountState from './accountState';
import DynamicVideo from './dynamicVideo';
import { datadogRum } from '@datadog/browser-rum';
import EmailConfirmation from './emailConfirmation';
import Logo from '../media/Morado_Lisa_Logo.png'
import { Button, Stack } from '@mui/material/'
import { Login } from './login'
import MsCallbackHandle from './MsCallbackHandle'
import GoogleCallbackHandle from './GoogleCallbackHandle'
import ConfirmPhoneNumber from './confirmPhoneNumber'
import { getSessionValue } from "../utils/SessionManager"

document.addEventListener('contextmenu', event => event.preventDefault());
let audioContext

export function App() {

    const [goIn, setGoIn] = useState()

    // Get the "code" parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const url_token = urlParams.get('result');
    const stored_token = getSessionValue("token")


    function userInteractionHandler() {
        setGoIn(true)
    }

    useEffect(() => {
        let stored_token = getSessionValue("token")

        if (stored_token && window.location.href === "/") {
            window.location.href = `/?url_token=${stored_token}`
        }
    }, [])

    const [token, setToken] = useState()
    if (!token && process.env.REACT_APP_DOMAIN === 'lisa.do' && process.env.REACT_APP_BLOCK_LOGIN) {
        return (
            <Login setToken={setToken} />
        );
    }
    else {
        if (!goIn && process.env.REACT_APP_DOMAIN === 'lisa.do' && !url_token && !code && !stored_token) {

            return (
                <React.Fragment>
                    <div className='splash-screem'>
                        <Stack spacing={2} className='splash-container'>
                            <img className="app-logo" src={Logo} alt="logo" />
                            <p className='app-title'>Conoce a Lisa, <br />tu nueva asistente personal</p>
                            <Button className={'btn-primary'} variant="outlined" onClick={() => userInteractionHandler()}>¡Iniciemos la conversación!</Button>
                        </Stack>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<DynamicVideo url_token={url_token} />} />
                            <Route path="/estadodecuenta/:id" element={<AccountState />} />
                            <Route path="/confirm-email/:id" element={<EmailConfirmation />} />
                            <Route path="/microsoft/callback" element={<MsCallbackHandle code={code} />} />
                            <Route path="/google/callback" element={<GoogleCallbackHandle code={code} />} />
                            <Route path="/confirm-phone" element={<ConfirmPhoneNumber url_token={url_token} />} />
                        </Routes>
                    </BrowserRouter>
                </React.Fragment>
            );
        }
    }
}

if (process.env.REACT_APP_ENV !== 'development') {
    datadogRum.init({
        applicationId: 'f3f0213f-729e-43ad-be76-64149843dd71',
        clientToken: 'pub48a1546464136811991fde5e364cda68',
        site: 'datadoghq.com',
        service: 'lisa-frontend',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording();
};

