import axios from 'axios';

export default async function getUserOrganizations(token) {
  let response = undefined;
  let error = undefined;
  let data = undefined;
  let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/get_user_organizations/`;

  try {
    response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    data = response.data;
  } catch (e) {
    if (e.response) {
      // Server responded with a status other than 200 range
      error = `${e.response.status} ${e.response.statusText}`;
    } else {
      // Something else happened while setting up the request
      error = e.message;
    }
  }

  return { data, error, response };
}
